import React from 'react';
import {Link, graphql} from 'gatsby';
import {Container} from 'react-bootstrap';
import {any} from 'prop-types';
import Layout from '../../components/layout';

const AnnouncementIndex = ({data}) => (
  <Layout>
    <Container>
      <div className="pt-5">
        <h1 className="border-bottom py-4">
          Announcements
          <small className="float-right text-muted">{data.allMdx.totalCount} Posts</small>
        </h1>
        {data.allMdx.edges.map(({node}) => (
          <div key={node.id} className="py-4 my-2 text-left border-bottom ">
            <h6>
              <small>
                <strong>{node.frontmatter.date}</strong> | Read Time: {node.timeToRead} minutes
              </small>
            </h6>
            <h3>
              <Link to={`/${node.slug}`}>{node.frontmatter.title}</Link>
            </h3>
            <p>{node.excerpt}</p>
          </div>
        ))}
      </div>
    </Container>
  </Layout>
);

AnnouncementIndex.propTypes = {
  data: any,
};

export default AnnouncementIndex;

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      totalCount
      edges {
        node {
          id
          slug
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
          excerpt
          timeToRead
        }
      }
    }
  }
`;
